import i18n from 'i18next'

import { initReactI18next } from 'react-i18next'

import LanguageDetector from 'i18next-browser-languagedetector'

import translationEN from './translations/en/translation.json'
import translationIT from './translations/it/translation.json'
import translationFR from './translations/fr/translation.json'

// variaible con le opzioni del LanguageDetector
const languageDetectorOptions = {
  // order and from where user language should be detected
  order: ['navigator', 'localStorage', 'cookie', 'querystring', 'htmlTag', 'path', 'subdomain'],
  // cache user language on
  caches: ['localStorage', 'cookie'],
  // excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
  // optional expire and domain for set cookie
  cookieMinutes: 10,
  // cookieDomain: 'myDomain',
  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement

  // only detect languages that are in the whitelist
  // checkWhitelist: true
}

// the translations
const resources = {
  en: translationEN,
  it: translationIT,
  fr: translationFR
}

i18n
// detect user language
// learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
// pass the i18n instance to react-i18next.
  .use(initReactI18next)
// init i18next
// for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // lng: 'it-IT',
    fallbackLng: {
      'it-IT': ['it'],
      'fr-FR': ['fr'],
      default: ['en']
    },

    debug: true,

    detection: languageDetectorOptions,

    resources,

    // keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  }, (err, t) => {
    if (err) return console.error('something went wrong loading i18next, err => ', err)
    // t('key') // -> same as i18next.t
  })

export default i18n
