import './App.css';
import QrReaderComponent from './QrCodeComponent';
import LogoImg from 'assets/logo/trusty-logo-black.svg'
import { useTranslation } from 'react-i18next'

function App() {
  const { t } = useTranslation(['startPage'])

  return (
    <div className="App">
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React Fastly
        </a>
      </header> */}
      <div className="App-header">
        <div className='logo-image'>
          <a href='https://www.trusty.id' target='_blank' rel="noreferrer">
          <img src={LogoImg} alt='' className='img-fluid' />
          </a>
        </div>
        <QrReaderComponent></QrReaderComponent>
      </div>
      <div className="button-container">
        <div className="inner-button-container">
        <button className="add-button">{t("buttonText")}</button>
        </div>
      </div>
    </div>
  );
}

export default App;
