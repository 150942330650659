import React, { useState } from 'react'
import QrReader from 'react-qr-reader'
import { useTranslation } from 'react-i18next'

const QrReaderComponent = () => {
  const { t } = useTranslation(['startPage'])
  const [result, setResult] = useState(t(''))

  const handleScan = data => {
    if (data) {
      setResult(data)
    }
  }
  const handleError = err => {
    console.error(err)
  }

  return (
      <>
      <div className='container-h2'>
      <h2 style={{color: '#3c4858'}}> <span className='freeDoka'>{t('text')}</span></h2>
      </div>
      <QrReader
        delay={300}
        onError={handleError}
        onScan={handleScan}
        style={{ width: '100%' }}
      />
      <a className='freeDoka' href={result} target='_blank' rel="noreferrer">{result}</a>
      </>
  )
}

export default QrReaderComponent
